import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Image from 'components/shared/ImageGatsby'
import TitleDefault from 'components/elements/TitleDefault'

const Content = styled(ParseContent)`
  & p {
    @media (min-width: 576px) {
      font-size: ${(props) => props.theme.font.size[18]};
    }
    @media (max-width: 575px) {
      font-size: ${(props) => props.theme.font.size[14]};
      text-align: center;
    }
  }
`

interface TeamProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Team
  location?: any
}

const Team: React.FC<TeamProps> = ({ fields }) => (
  <section>
    <div className="container py-5">
      <TitleDefault>Team</TitleDefault>
      <div className="row">
        {fields.member?.map((member: any, index: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className="col-6 mb-4 mb-md-0 col-md-3" key={index}>
            <Image image={member?.image} loading="lazy" />
            <Content content={member?.description} className="mt-3" />
          </div>
        ))}
      </div>
    </div>
  </section>
)

export default Team
